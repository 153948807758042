require('dotenv').config()

const {
  DEFAULT_LANG = 'en',
  DEFAULT_FRONTPAGE_TAG = 'DEFAULT_FRONTPAGE'
} = process.env

const getLinkResolver = (defaultLang, defaultFrontpageTag) => page => {
  if (!page.lang && !page.uid) {
    return
  }

  const lang = page.lang.substring(0, 2)
  const isFront = page.tags && page.tags.indexOf(defaultFrontpageTag) !== -1

  const parts = ['/']

  if (lang !== defaultLang) {
    parts.push(lang, '/')
  }
  if (!isFront) {
    if (page.type === 'event') {
      parts.push('event/', page.uid)
    } else {
      parts.push(page.uid, '/')
    }
  }
  return parts.join('')
}

const linkResolver = getLinkResolver(DEFAULT_LANG, DEFAULT_FRONTPAGE_TAG)

module.exports = {
  linkResolver,
  getLinkResolver
}
